import React from 'react'
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from "react-helmet"
import '@fontsource/open-sans';
// import '@fontsource/alfa-slab-one';

import GlobalStyles from '../styles/global';
import theme from '../styles/theme';

const Layout: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"/>
        <meta name="msapplication-TileColor" content="#118AB2"/>
        <meta name="theme-color" content="#ffffff"/>
        <title>Jack Harding | Web Developer</title>
      </Helmet>

      <GlobalStyles />
      <Content>
        {children}
      </Content>
    </ThemeProvider>
  )
}

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 38.25rem;
`;

export default Layout
