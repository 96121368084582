import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

import Text, { TextProps } from './Text';

const DEFAULT_COLOR = 'text';

type TextButtonProps = TextProps & {}

const TextButton = styled(Text).attrs(props => ({
  as: 'button',
  ...props
}))`
  position: relative;
  padding: 0 0 0.75rem;
  border: 0;
  background: none;
  transition: color .15s;
  text-decoration: none;

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: currentColor;
  }

  &:after {
    background: ${({ theme }) => theme.colors.primaryLight};
    transition: transform .15s;
    transform: scaleX(0);
    transform-origin: 0 0;
  }

  &:hover {
    /* color: ${({ theme }) => theme.colors.primaryLight}; */

    &:after {
      transform: scaleX(1);
    }
  }
`;
// const TextButton: React.FC<TextButtonProps> = ({ children, color = DEFAULT_COLOR, ...props }) => (
//   <StyledButton color={color}>
//     <Text 
//       color={color} 
//       {...props}
//     >{ children }</Text>
//   </StyledButton>
// );

type StyledButtonProps = Required<Pick<TextButtonProps, 'color'>>;
const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  padding: 0.15rem 0;
  border: 0;
  background: none;
  color: ${({ theme, color }) => theme.colors[color]};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: currentColor;
  }
`;

TextButton.defaultProps = {
  color: 'text',
  size: 'lg',
}

export default TextButton;