import styled, { DefaultTheme } from 'styled-components';

export type TextProps = {
  color?: keyof DefaultTheme['colors'];
  size?: keyof DefaultTheme['fontSize']['body'];
  weight?: keyof DefaultTheme['fontWeight'];
}

const DEFAULT_COLOR = 'text';
const DEFAULT_SIZE = 'lg';
const DEFAULT_WEIGHT = 'normal';

const Text = styled.p<TextProps>`
  color: ${({ theme, color = DEFAULT_COLOR }) => theme.colors[color]};
  font-size: ${({ theme, size = DEFAULT_SIZE }) => theme.fontSize.body[size]};
  font-weight: ${({ theme, weight = DEFAULT_WEIGHT }) => theme.fontWeight[weight]};

  a {
    color: inherit;
    font-weight: 700;
    text-decoration: none;
    transition: color .2s;

    &:hover {
      color: #ffce53;
    }
  }
`;

export default Text;