import * as React from 'react';

import Hero from '../components/Hero';
import About from '../components/About';
import Work from '../components/Work';
import Layout from '../components/Layout';
import styled from 'styled-components';

const IndexPage: React.FC = () => {
  return (
    <Layout>
      {/* <Hero /> */}
      <About />
      {/* <Work /> */}
    </Layout>
  );
}


export default IndexPage;
