import styled, { DefaultTheme } from 'styled-components';

type TitleProps = {
  color?: keyof DefaultTheme['colors'];
  size?: 'sm' | 'md' | 'lg';
}

const DEFAULT_COLOR = 'text';
const DEFAULT_SIZE = 'lg';

const Title = styled.h1<TitleProps>`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme, color = DEFAULT_COLOR }) => theme.colors[color]};
  font-size: ${({ theme, size = DEFAULT_SIZE }) => theme.fontSize.title[size]};
  font-weight: 700;
`;

Title.defaultProps = {
  color: 'text',
  size: 'lg'
}

export default Title;