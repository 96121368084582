import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

// import { focus } from './common';

const globalStyles = createGlobalStyle`
	${normalize};

	@font-face {
		font-family: "Baron Neue";
		src: url("/fonts/BaronNeueBold.woff2") format("woff2"),
       url("/fonts/BaronNeueBold.woff") format("woff");
    font-display: block;
	}

	html {
    height: auto;
		overflow-x: hidden;
		font-size: 16px;
    scroll-behavior: smooth;
		/* @media (max-width: 767px) {
			font-size: 14px;
		}
		@media (max-width: 499px) {
			font-size: 12px;
		} */
	}

	*,*:before,*:after {
		box-sizing: border-box;
	}

	body {
		line-height: 1.5;
		font-family: ${({ theme }) => theme.fontFamily.body};
		color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.primary};
	}

  img,
	svg {
		display: block;
    max-width: 100%;
	}

  p {
    margin: 0 0 ${({ theme }) => theme.spacing[5]};
  }

  a, button {
    cursor: pointer;
  }
`;

export default globalStyles;
