const theme = {
  colors: {
    text: '#fff',
    primary: '#095F7B',
    primaryLight: '#00B4D8',
    inverted: '#fff',
  },
  fontFamily: {
    display: '"Alfa Slab One", display',
    title: '"Open Sans", sans-serif',
    body: '"Open Sans", sans-serif',
  },
  fontWeight: {
    normal: 400,
    semi: 600,
    bold: 700,
  },
  fontSize: {
    body: {
      sm: '0.875rem',
      md: '1rem',
      lg: '1.125rem',
    },
    title: {
      sm: '1.125rem',
      md: '1.25rem',
      lg: '1.4375rem',
    },
  },
  spacing: {
    px: '1px',
    "1": '0.25rem',
    "2": '0.5rem',
    "3": '0.75rem',
    "4": '1rem',
    "5": '1.25rem',
    "6": '1.5rem',
    "7": '1.75rem',
    "8": '2rem',
    "9": '2.25rem',
    "10": '2.5rem',
    "11": '2.75rem',
    "12": '3rem',
    "13": '3.25rem',
    "14": '3.5rem',
    "15": '3.75rem',
    "16": '4rem',
    "17": '4.25rem',
    "18": '4.5rem',
    "19": '4.75rem',
    "20": '5rem',
    "21": '5.25rem',
    "22": '5.5rem',
    "23": '5.75rem',
    "24": '6rem',
    padding: '1.375rem',
    textWidth: '25.25rem',
  }
};

export type ThemeType = typeof theme;

export default theme;